<template lang="">
<Company/>
</template>
<script>
export default {
  components: {
    Company: () => import("@/components/manage/Company.vue"),
  },
};
</script>
<style lang=""></style>
